import React, { useEffect, useState, useRef } from "react"
import { connect } from "react-redux"
import {
  Typography,
  Hidden,
  Button,
  Box,
  Select,
  MenuItem,
  Divider,
  ListSubheader,
  Checkbox,
  FormControlLabel,
} from "@mui/material"
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined"
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined"
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined"
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline"
import { makeStyles } from "@mui/styles"
import { getCaregivers as fetchCaregivers } from "redux/onboarding"
import {
  setCaregiver as putCaregiver,
  removeCaregiver as unSelectCaregiver,
} from "redux/caregiver"
import config from "config"
import store from "redux/store"
import posthog from "posthog-js"
import { hideAlert, showAlert } from "redux/common"
import { useTheme } from "@mui/material/styles"
import { useNavigate } from "react-router-dom"
import LoadingScreen from "components/LoadingScreen"
import { trackEvent } from "utils/trackEvent"
import { capitalize } from "utils/capitalize"
import NotifyFrikortModal from "components/NotifyFrikortModal"
import NotifyFrikortSticky from "components/NotifyFrikortSticky"
import CaregiverCard from "./components/CaregiverCard"
import CaregiverInfo from "./components/CaregiverInfo"

const { abTestFeatureFlag, abTestGroupName } = config

const caregiversCacheTimeInMs = 5 * 60 * 1000

let abTestGroup = false

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    position: "relative",
  },
  loading: {
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    position: "absolute",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
  },
  wrapper: {
    flexGrow: 1,
    overflow: "auto",
  },
  hiddenMobile: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  headerSection: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.white,
    textAlign: "center",
    padding: theme.spacing(3, 1),
    "& .MuiTypography-subtitle2": {
      fontSize: "1.3rem",
    },
    "& .MuiTypography-h6": {
      fontSize: "1.4rem",
    },
    "& .MuiTypography-body2": {
      fontSize: "1rem",
    },
  },
  title: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(2.5),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2, 3, 1),
      "& .MuiTypography-subtitle1": {
        fontSize: "1rem !important",
      },
    },
    [theme.breakpoints.down(330)]: {
      padding: theme.spacing(2),
    },
    "& .MuiTypography-subtitle1": {
      fontSize: "1.3rem",
      marginBottom: 0,
    },
    "& .MuiTypography-body2": {
      fontSize: "1.1rem",
    },
  },
  rowContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "center",
    },
    [theme.breakpoints.down("sm")]: {
      alignItems: "flex-start",
    },
  },
  selectContainer: {
    marginTop: 0,
    padding: theme.spacing(0, 2),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(0, 2),
    },
  },
  selectLabel: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: theme.spacing(2),
    "& .MuiTypography-root": {
      fontSize: "1rem",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(1),
    },
  },
  select: {
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(1),
    color: theme.palette.primary.main,
    borderRadius: "28px",
    maxHeight: "300px",
    fontWeight: 600,
    paddingLeft: theme.spacing(1, 1.75),
    "& svg": {
      display: "none",
    },
    "& .MuiSelect-icon": {
      display: "block !important",
    },
    "& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
      {
        padding: theme.spacing(1.75, 2.75),
      },
  },
  mobileSelect: {
    position: "absolute",
    margin: "0 auto",
    width: "90%",
    zIndex: 10,
    height: "90%",
    top: theme.spacing(3),
    bottom: theme.spacing(3),
    overflowY: "auto",
    borderRadius: theme.spacing(2),
    background: theme.palette.white,
    boxShadow: "6px 6px 20px rgba(0, 0, 0, 0.5);",
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(0, 2),
  },
  mobileSelectHeader: {
    textAlign: "center",
    color: theme.palette.primary.main,
    padding: theme.spacing(1),
    fontWeight: 600,
  },
  errorFilter: {
    padding: theme.spacing(1, 2, 1),
    display: "flex",
    justifyContent: "space-between",
    position: "sticky",
    bottom: "60px",
    background: theme.palette.white,
    marginBottom: theme.spacing(-1),
    color: theme.palette.error.main,
  },
  selectButtons: {
    padding: theme.spacing(1, 2, 1),
    display: "flex",
    justifyContent: "space-between",
    position: "sticky",
    bottom: 0,
    background: theme.palette.white,
    marginBottom: theme.spacing(-1),
    minHeight: "60px",
  },
  selectButton: {
    textTransform: "none",
    borderRadius: theme.spacing(12.5),
    width: "40%",
    boxShadow: "none",
    lineHeight: 2.1,
  },
  cancelFilterButton: {
    background: "#EBE6FC",
    color: "#603659",
    width: "200px",
    borderRadius: theme.spacing(12.5),
    lineHeight: 2.1,
    height: theme.spacing(7),
    [theme.breakpoints.down("sm")]: {
      width: "140px",
      lineHeight: 1.7,
      height: theme.spacing(5),
      fontSize: "0.65rem",
    },
  },
  menuItem: {
    justifyContent: "space-between",
    "& svg": {
      color: theme.palette.primary.main,
    },
  },
  menuItemCheckBox: {
    justifyContent: "flex-start",
  },
  columnContainer: {
    maxWidth: 460,
    width: 460,
    display: "flex",
    flexGrow: 1,
    flexDirection: "column",
    justifyContent: "end",
    boxSizing: "border-box",
    [theme.breakpoints.down("md")]: {
      maxWidth: 530,
      width: 530,
    },
    "&:nth-child(even)": {
      paddingLeft: Number(theme.spacing(3).slice(0, -2)) / 2,
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100% !important",
      width: "100% !important",
    },
  },
  marginTop: {
    marginTop: theme.spacing(1),
  },
  caregiverCard: {
    display: "grid",
    gridTemplateColumns: "auto auto",
    justifyContent: "space-between",
    width: "920px",
    padding: theme.spacing(2, 0.5),
  },
  email: {
    fontWeight: "600",
    display: "inline-block",
  },
  errorIcon: {
    height: 200,
    width: 200,
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      height: 150,
      width: 150,
    },
  },
  errorButton: {
    marginTop: theme.spacing(2),
  },
  noCaregiversRow: {
    marginRight: theme.spacing(15),
    [theme.breakpoints.down("md")]: {
      marginRight: 0,
      marginLeft: theme.spacing(4),
    },
    [theme.breakpoints.down(330)]: {
      marginLeft: theme.spacing(2),
    },
  },
  titleColumnContainer: {
    "&:first-of-type": {
      width: "530px",
      maxWidth: "530px",
    },
    "&:last-of-type": {
      width: "390px",
      maxWidth: "390px",
    },
  },
}))

const getDateFormattedString = (date) =>
  date.toLocaleDateString("sv-SE", {
    weekday: "long",
    day: "numeric",
    month: "long",
  })

const getDateString = (date) => {
  const formatedDate = new Date(date)
  return formatedDate.toDateString()
}

const getNext14Days = () => {
  const today = new Date()
  const dates = []
  for (let i = 0; i < 14; i += 1) {
    const nextDate = new Date(today)
    nextDate.setDate(today.getDate() + i)
    if (i === 0) {
      dates.push({ name: "Idag", value: getDateString(nextDate) })
    } else if (i === 1) {
      dates.push({ name: "Imorgon", value: getDateString(nextDate) })
    } else {
      dates.push({
        name: getDateFormattedString(nextDate),
        value: getDateString(nextDate),
      })
    }
  }
  return dates
}

const CardContainer = ({ caregivers, className, selectCaregiver, userId }) => (
  <div className={className}>
    {caregivers.map((caregiver, index) => (
      <CaregiverCard
        onCaregiverSelect={(chosenCaregiver) => {
          trackEvent("selectTherapist", {
            userId,
            therapist: chosenCaregiver?.id,
            featured: chosenCaregiver?.recommended ? "yes" : "no",
            position: chosenCaregiver?.position || index + 1,
          })
          selectCaregiver(chosenCaregiver)
        }}
        caregiver={caregiver}
        key={caregiver.id}
      />
    ))}
  </div>
)

const OnBoarding = ({
  getCaregivers,
  setCaregiver,
  removeCaregiver,
  caregivers,
  caregiversFetchTime,
  loading,
  error,
  userId,
  userFirstName,
  freeCard,
  paidSessionNumber,
}) => {
  const classes = useStyles()
  const theme = useTheme()
  const navigate = useNavigate()
  const [selectedCaregiver, setSelectedCaregiver] = useState(null)
  const [sortBy, setSortBy] = useState("recommended")
  const [filterDays, setFilterDays] = useState([])
  const [filterHours, setFilterHours] = useState([])
  const [stepNumber, setStepNumber] = useState("1")
  const [filterError, setFilterError] = useState(false)
  const [showFrikortModal, setShowFrikortModal] = useState(false)
  const isMobileView = window.innerWidth < theme.breakpoints.values.sm
  const isNewUser = paidSessionNumber === 0

  const scrollContainerRef = useRef(null)
  const ITEM_HEIGHT = 48
  const ITEM_PADDING_TOP = 8
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 8 + ITEM_PADDING_TOP,
      },
    },
  }

  if (isNewUser) {
    posthog.identify(userId)
    abTestGroup = posthog.getFeatureFlag(abTestFeatureFlag) === abTestGroupName
  }

  const exitConfirmation = useRef(false)

  const handleScrollPosition = () => {
    const scrollPosition = sessionStorage.getItem("onboardingScrollPosition")

    if (scrollPosition && scrollContainerRef.current) {
      // Offset the scroll position to ensure router scrolling is not interfering.
      setTimeout(() => {
        scrollContainerRef.current.scrollTo({ top: parseInt(scrollPosition) })
      }, 0)

      sessionStorage.removeItem("onboardingScrollPosition")
    }
  }

  const handleChangeSorting = (event) => {
    if (event.target.value !== "specific_days") {
      setStepNumber("1")
    }
    setSortBy(event.target.value)
  }

  const handleBack = () => {
    if (stepNumber === "1") {
      setSortBy("recommended")
    } else {
      setStepNumber("1")
    }
  }

  const handleSubmitTimes = () => {
    if (stepNumber === "2") {
      if (filterHours.length === 0) {
        setFilterError(true)
        return
      }
      getCaregivers({ userId, sortBy, filterDays, filterHours })
      setStepNumber("3")
    } else {
      if (filterDays.length === 0) {
        setFilterError(true)
        return
      }
      setStepNumber("2")
    }
    setFilterError(false)
  }

  const handleSelectDate = (eventOrValue) => {
    let value
    if (typeof eventOrValue === "string") {
      value = eventOrValue
    } else {
      value = eventOrValue.target.value
    }

    const next14Days = getNext14Days().map((item) => item.value)

    if (value === "all" || (Array.isArray(value) && value.includes("all"))) {
      setFilterDays(filterDays.length === next14Days.length ? [] : next14Days)
      return
    }

    if (typeof value === "string") {
      setFilterDays(
        filterDays.includes(value)
          ? filterDays.filter((day) => day !== value)
          : [...filterDays, value]
      )
    } else {
      setFilterDays(value)
    }
  }

  const handleSelectTimes = (eventOrValue) => {
    let value
    if (typeof eventOrValue === "string") {
      value = eventOrValue
    } else {
      value = eventOrValue.target.value
    }

    const availableTimes = ["morning", "afternoon", "evening"]

    if (value === "all" || (Array.isArray(value) && value.includes("all"))) {
      setFilterHours(
        filterHours.length === availableTimes.length ? [] : availableTimes
      )
      return
    }

    if (typeof value === "string") {
      setFilterHours(
        filterHours.includes(value)
          ? filterHours.filter((time) => time !== value)
          : [...filterHours, value]
      )
    } else {
      setFilterHours(value)
    }
  }

  const handleCancelFilter = () => {
    setStepNumber("1")
  }

  const handleGetIsFrikortModalShown = () => {
    const existingFrecard = JSON.parse(localStorage.getItem("frikort"))
    const isFreeCardValid = freeCard.expirationDate !== null
    const shouldShowModal =
      !existingFrecard || existingFrecard.id !== freeCard.id

    if (isFreeCardValid && shouldShowModal) {
      setShowFrikortModal(true)
    }
  }

  useEffect(() => {
    // Attempt to restore scrolling position on component mount
    handleGetIsFrikortModalShown()
    handleScrollPosition()
  }, [])

  useEffect(() => {
    if (
      !loading &&
      !selectedCaregiver &&
      (!caregivers ||
        Date.now() - caregiversFetchTime > caregiversCacheTimeInMs)
    ) {
      getCaregivers({ userId, sortBy })
    }
    if (selectedCaregiver) {
      removeCaregiver()
    }

    navigate("/")

    const handlePopstate = () => {
      if (selectedCaregiver) {
        setSelectedCaregiver(null)
        handleScrollPosition()
      } else if (isMobileView) {
        if (!exitConfirmation.current) {
          exitConfirmation.current = true

          // Show your exit confirmation message
          store.dispatch(
            showAlert({
              type: "info",
              message: "Tryck bakåt igen för att avsluta",
              duration: 10000,
            })
          )
          navigate("/")

          setTimeout(() => {
            exitConfirmation.current = false
          }, 10000)
        } else {
          navigate(-1)
        }
      }
    }

    window.addEventListener("popstate", handlePopstate)
    navigate("/")

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("popstate", handlePopstate)
    }
  }, [selectedCaregiver])

  useEffect(() => {
    if (sortBy !== "specific_days") {
      getCaregivers({ userId, sortBy })
    }
  }, [sortBy])

  const handleSelectCaregiver = (caregiver) => {
    store.dispatch(hideAlert({ type: "info" }))
    setSelectedCaregiver(caregiver)

    if (scrollContainerRef.current) {
      sessionStorage.setItem(
        "onboardingScrollPosition",
        scrollContainerRef.current.scrollTop
      )

      scrollContainerRef.current.scrollTo({ top: 0 })
    }
  }

  const handleCloseFrikortModal = () => {
    setShowFrikortModal(false)
    localStorage.setItem("frikort", JSON.stringify(freeCard))
  }

  if (loading) {
    return <LoadingScreen />
  }
  if (error) {
    return (
      <div className={classes.root}>
        <Box className={classes.loading}>
          <ErrorOutlineIcon color="primary" className={classes.errorIcon} />
          <Typography variant="h4">Något gick fel!</Typography>
          <Button
            variant="contained"
            onClick={() => getCaregivers({ userId, sortBy })}
            className={classes.errorButton}
          >
            Försök igen
          </Button>
        </Box>
      </div>
    )
  }

  if (caregivers) {
    return (
      <div className={classes.root}>
        <div className={classes.container}>
          <div ref={scrollContainerRef} className={classes.wrapper}>
            {!selectedCaregiver ? (
              <>
                <div
                  className={`${
                    !isNewUser && !abTestGroup ? classes.hiddenMobile : ""
                  }`}
                >
                  <div className={classes.headerSection}>
                    <Typography variant="subtitle2" gutterBottom>
                      Hej {userFirstName},
                    </Typography>
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{ fontWeight: 600 }}
                    >
                      Välkommen till Din Psykolog
                    </Typography>
                    <Typography variant="body2">
                      Vid livshotande fara ring genast 112!
                    </Typography>
                  </div>
                  <div className={classes.rowContainer}>
                    <div
                      className={`${classes.columnContainer} ${classes.titleColumnContainer}`}
                    >
                      <div className={classes.title}>
                        <Typography
                          variant="subtitle1"
                          gutterBottom
                          sx={{ fontWeight: 600 }}
                        >
                          Välj en psykolog och tid som passar dig
                        </Typography>
                      </div>
                    </div>
                    <div
                      className={`${classes.columnContainer} ${classes.titleColumnContainer}`}
                    />
                  </div>
                </div>
                <div className={classes.rowContainer}>
                  <div
                    className={`${classes.columnContainer} ${classes.selectContainer}`}
                  >
                    <Box className={classes.selectLabel}>
                      <Typography variant="body2">
                        Sortera psykologer efter:
                      </Typography>
                      {stepNumber === "3" && (
                        <Hidden smUp>
                          <Button
                            variant="small"
                            className={classes.cancelFilterButton}
                            onClick={handleCancelFilter}
                          >
                            Ändra filter
                            <FilterListOutlinedIcon />
                          </Button>
                        </Hidden>
                      )}
                    </Box>
                    <Box style={{ display: "flex" }}>
                      {(sortBy !== "specific_days" || stepNumber === "3") && (
                        <Select
                          fullWidth
                          value={sortBy}
                          onChange={handleChangeSorting}
                          inputProps={{ "aria-label": "Without label" }}
                          className={classes.select}
                          IconComponent={KeyboardArrowDownOutlinedIcon}
                        >
                          <MenuItem
                            value="recommended"
                            className={classes.menuItem}
                          >
                            Rekommenderade psykologer
                            {sortBy === "recommended" && <CheckOutlinedIcon />}
                          </MenuItem>
                          <Divider component="li" />
                          <MenuItem
                            value="next_available"
                            className={classes.menuItem}
                          >
                            Nästa lediga tid
                            {sortBy === "next_available" && (
                              <CheckOutlinedIcon />
                            )}
                          </MenuItem>
                          {!isNewUser && !abTestGroup && (
                            <Divider component="li" />
                          )}
                          {!isNewUser && !abTestGroup && (
                            <MenuItem
                              value="specific_days"
                              className={classes.menuItem}
                            >
                              Välj datum och tid
                              {sortBy === "specific_days" && (
                                <CheckOutlinedIcon />
                              )}
                            </MenuItem>
                          )}
                        </Select>
                      )}
                      <Hidden smDown>
                        {sortBy === "specific_days" && stepNumber === "1" && (
                          <Select
                            fullWidth
                            multiple
                            value={filterDays}
                            onChange={handleSelectDate}
                            className={classes.select}
                            IconComponent={KeyboardArrowDownOutlinedIcon}
                            displayEmpty
                            defaultOpen
                            open
                            MenuProps={MenuProps}
                            renderValue={() => <>Välj datum och tid</>}
                            inputProps={{ "aria-label": "Without label" }}
                          >
                            <ListSubheader>
                              Välj datum (Steg 1 av 2)
                            </ListSubheader>
                            <MenuItem
                              value="all"
                              className={classes.menuItemCheckBox}
                            >
                              <Checkbox
                                checked={
                                  filterDays.length === getNext14Days().length
                                }
                              />
                              Alla dagar går bra
                            </MenuItem>
                            {getNext14Days().map((item, index) => (
                              <MenuItem
                                key={index}
                                value={item.value}
                                className={classes.menuItemCheckBox}
                              >
                                <Checkbox
                                  checked={filterDays.indexOf(item.value) > -1}
                                />
                                {capitalize(item.name)}
                              </MenuItem>
                            ))}
                            {filterError && (
                              <Box className={classes.errorFilter}>
                                Välj minst ett alternativ ovan för att gå vidare
                              </Box>
                            )}
                            <Box className={classes.selectButtons}>
                              <Button
                                className={classes.selectButton}
                                variant="contained"
                                onClick={() => handleBack()}
                              >
                                Tillbaka
                              </Button>
                              <Button
                                className={classes.selectButton}
                                variant="contained"
                                onClick={() => handleSubmitTimes()}
                              >
                                Nästa
                              </Button>
                            </Box>
                          </Select>
                        )}
                        {sortBy === "specific_days" && stepNumber === "2" && (
                          <Select
                            fullWidth
                            multiple
                            value={filterHours}
                            onChange={handleSelectTimes}
                            className={classes.select}
                            IconComponent={KeyboardArrowDownOutlinedIcon}
                            renderValue={() => <>Välj datum och tid</>}
                            inputProps={{ "aria-label": "Without label" }}
                            displayEmpty
                            defaultOpen
                            open
                            MenuProps={MenuProps}
                          >
                            <ListSubheader>
                              Välj datum (Steg 2 av 2)
                            </ListSubheader>
                            <MenuItem
                              value="all"
                              className={classes.menuItemCheckBox}
                            >
                              <Checkbox checked={filterHours.length === 3} />
                              Alla tider går bra
                            </MenuItem>
                            <MenuItem
                              value="morning"
                              className={classes.menuItemCheckBox}
                            >
                              <Checkbox
                                checked={filterHours.includes("morning")}
                              />
                              Förmiddag kl 07:00 - 12:00
                            </MenuItem>
                            <MenuItem
                              value="afternoon"
                              className={classes.menuItemCheckBox}
                            >
                              <Checkbox
                                checked={filterHours.includes("afternoon")}
                              />
                              Eftermiddag kl 12:00 - 17:00
                            </MenuItem>
                            <MenuItem
                              value="evening"
                              className={classes.menuItemCheckBox}
                            >
                              <Checkbox
                                checked={filterHours.includes("evening")}
                              />
                              Kväll kl 17:00 - 00:00
                            </MenuItem>
                            {filterError && (
                              <Box className={classes.errorFilter}>
                                Välj minst ett alternativ ovan för att gå vidare
                              </Box>
                            )}
                            <Box className={classes.selectButtons}>
                              <Button
                                className={classes.selectButton}
                                variant="contained"
                                onClick={() => handleBack()}
                              >
                                Tillbaka
                              </Button>
                              <Button
                                className={classes.selectButton}
                                variant="contained"
                                onClick={() => handleSubmitTimes()}
                              >
                                Nästa
                              </Button>
                            </Box>
                          </Select>
                        )}
                      </Hidden>
                      <Hidden smUp>
                        {sortBy === "specific_days" && stepNumber === "1" && (
                          <Box className={classes.mobileSelect}>
                            <Typography className={classes.mobileSelectHeader}>
                              Välj datum (Steg 1 av 2)
                            </Typography>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    filterDays.length === getNext14Days().length
                                  }
                                  onChange={() => handleSelectDate("all")}
                                />
                              }
                              label="Alla dagar går bra"
                            />
                            {getNext14Days().map((item, index) => (
                              <FormControlLabel
                                key={index}
                                control={
                                  <Checkbox
                                    checked={filterDays.includes(item.value)}
                                    onChange={() =>
                                      handleSelectDate(item.value)
                                    }
                                  />
                                }
                                label={capitalize(item.name)}
                              />
                            ))}
                            {filterError && (
                              <Box className={classes.errorFilter}>
                                Välj minst ett alternativ ovan för att gå vidare
                              </Box>
                            )}
                            <Box className={classes.selectButtons}>
                              <Button
                                className={classes.selectButton}
                                variant="contained"
                                onClick={handleBack}
                              >
                                Tillbaka
                              </Button>
                              <Button
                                className={classes.selectButton}
                                variant="contained"
                                onClick={handleSubmitTimes}
                              >
                                Nästa
                              </Button>
                            </Box>
                          </Box>
                        )}

                        {sortBy === "specific_days" && stepNumber === "2" && (
                          <Box className={classes.mobileSelect}>
                            <Typography className={classes.mobileSelectHeader}>
                              Välj datum (Steg 2 av 2)
                            </Typography>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={filterHours.length === 3}
                                  onChange={() => handleSelectTimes("all")}
                                />
                              }
                              label="Alla tider går bra"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={filterHours.includes("morning")}
                                  onChange={() => handleSelectTimes("morning")}
                                />
                              }
                              label="Förmiddag kl 07:00 - 12:00"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={filterHours.includes("afternoon")}
                                  onChange={() =>
                                    handleSelectTimes("afternoon")
                                  }
                                />
                              }
                              label="Eftermiddag kl 12:00 - 17:00"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={filterHours.includes("evening")}
                                  onChange={() => handleSelectTimes("evening")}
                                />
                              }
                              label="Kväll kl 17:00 - 00:00"
                            />
                            {filterError && (
                              <Box className={classes.errorFilter}>
                                Välj minst ett alternativ ovan för att gå vidare
                              </Box>
                            )}
                            <Box className={classes.selectButtons}>
                              <Button
                                className={classes.selectButton}
                                variant="contained"
                                onClick={handleBack}
                              >
                                Tillbaka
                              </Button>
                              <Button
                                className={classes.selectButton}
                                variant="contained"
                                onClick={handleSubmitTimes}
                              >
                                Nästa
                              </Button>
                            </Box>
                          </Box>
                        )}
                      </Hidden>
                    </Box>
                  </div>
                  <div
                    className={`${classes.columnContainer} ${classes.selectContainer}`}
                  >
                    {stepNumber === "3" && (
                      <Hidden smDown>
                        <Button
                          className={classes.cancelFilterButton}
                          onClick={handleCancelFilter}
                        >
                          Ändra filter
                          <FilterListOutlinedIcon />
                        </Button>
                      </Hidden>
                    )}
                  </div>
                </div>
                <div className={classes.rowContainer}>
                  <Hidden mdUp>
                    <CardContainer
                      selectCaregiver={(caregiver) =>
                        handleSelectCaregiver(caregiver)
                      }
                      caregivers={caregivers.length ? caregivers : []}
                      userId={userId}
                      className={classes.marginTop}
                    />
                  </Hidden>
                  <Hidden mdDown>
                    <CardContainer
                      selectCaregiver={(caregiver) =>
                        handleSelectCaregiver(caregiver)
                      }
                      caregivers={caregivers.length ? caregivers : []}
                      userId={userId}
                      className={`${classes.caregiverCard}`}
                    />
                  </Hidden>
                </div>

                {caregivers.length === 0 && (
                  <div
                    className={`${classes.rowContainer} ${classes.noCaregiversRow}`}
                  >
                    <Typography variant="subtitle1">
                      Just nu har vi inga lediga psykologer, maila oss gärna på{" "}
                      <Typography variant="inherit" className={classes.email}>
                        info@dinpsykolog.se
                      </Typography>
                      {", "}
                      så hjälper vi dig.
                    </Typography>
                  </div>
                )}
              </>
            ) : (
              <CaregiverInfo
                caregiver={selectedCaregiver}
                goBack={async () => {
                  await setSelectedCaregiver(null)
                  handleScrollPosition()
                }}
                setCaregiver={(caregiver) => {
                  trackEvent("findTimeslot", {
                    userId,
                  })
                  setCaregiver({
                    loading: false,
                    data: caregiver,
                    error: false,
                  })
                }}
              />
            )}
          </div>
        </div>
        <NotifyFrikortModal
          open={showFrikortModal}
          close={handleCloseFrikortModal}
          freeCard={freeCard}
        />
        {!showFrikortModal && freeCard.expirationDate && !selectedCaregiver && (
          <NotifyFrikortSticky
            type="sticky"
            expirationDate={freeCard.expirationDate}
          />
        )}
      </div>
    )
  }

  return null
}

const mapStateToProps = ({ user, onboarding }) => {
  const {
    id: userId,
    firstName: userFirstName,
    paidSessionNumber,
    freeCard,
  } = user
  const { chooseCaregiver } = onboarding
  const { caregivers, caregiversFetchTime, loading, error } = chooseCaregiver

  return {
    userId,
    userFirstName,
    freeCard,
    caregivers,
    caregiversFetchTime,
    loading,
    error,
    paidSessionNumber,
  }
}

const mapDispatchToProps = (dispatch) => ({
  getCaregivers: ({ userId, sortBy, filterDays, filterHours }) =>
    dispatch(fetchCaregivers({ userId, sortBy, filterDays, filterHours })),
  setCaregiver: (caregiver) => dispatch(putCaregiver(caregiver)),
  removeCaregiver: () => dispatch(unSelectCaregiver()),
})

export default connect(mapStateToProps, mapDispatchToProps)(OnBoarding)
