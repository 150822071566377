import { differenceInYears, isValid } from "date-fns"

/**
 * This functions calculates user age from social security number.
 * If user is under 20 it will return the age the user will turn the current year
 *
 * @param {string|number} ssn
 * @returns {number}
 */

function getUserAge(ssn, referenceDate) {
  try {
    const socialSecurity = ssn.toString()
    // extract year, month, day
    const regex = /^(\d{4})(\d{2})(\d{2})/
    const {
      1: year,
      2: zeroIndexedMonth,
      3: day,
    } = socialSecurity.match(regex) || {}

    // the month is 0-indexed
    const month = zeroIndexedMonth <= 0 ? 0 : zeroIndexedMonth - 1

    const date = referenceDate ? new Date(referenceDate) : new Date()
    const birthDate = new Date(Date.UTC(year, month, day))

    if (!isValid(birthDate)) {
      throw new Error("Invalid birth date")
    }

    const age = differenceInYears(date, birthDate)

    return age
  } catch (e) {
    return NaN
  }
}

export default getUserAge
