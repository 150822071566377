import { Modal, Box, Typography, Button } from "@mui/material"
import { makeStyles } from "@mui/styles"
import CloseIcon from "@mui/icons-material/Close"
import { getFormattedFreeCardDate } from "utils/getFormattedFreeCardDate"

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(4),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  title: {
    color: theme.palette.primary.main,
    textAlign: "center",
    fontWeight: theme.typography.fontWeightBold,
  },
  wrapper: {
    backgroundColor: theme.palette.lighter_gray,
    padding: theme.spacing(3, 4, 2),
    borderRadius: theme.spacing(2),
    outline: "none",
    [theme.breakpoints.up("sm")]: {
      maxWidth: "50%",
    },
    position: "relative",
  },
  iconContainer: {
    position: "absolute",
    right: theme.spacing(1.5),
    top: theme.spacing(1.5),
    width: theme.spacing(2),
    height: theme.spacing(2),
  },
  closeIcon: {
    cursor: "pointer",
    color: theme.palette.primary.main,
    width: "100%",
    height: "100%",
  },
  text: {
    color: theme.palette.black,
    textAlign: "center",
    fontSize: "0.8rem",
    lineHeight: "1.2rem",
  },
  buttonContainer: {
    display: "flex",
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-evenly",
    marginTop: theme.spacing(3),
  },
  button: {
    display: "flex",
    padding: theme.spacing(1),
    textTransform: "none",
    background: theme.palette.primary.main,
    color: theme.palette.white,
    width: "50%",
    [theme.breakpoints.down("sm")]: {
      width: "80%",
    },
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
}))

const NotifyFrikortModal = ({ open, close, freeCard }) => {
  const classes = useStyles()
  const { expirationDate } = freeCard

  return (
    <Modal className={classes.root} open={open} onClose={close}>
      <Box className={classes.wrapper}>
        <Box className={classes.iconContainer}>
          <CloseIcon
            className={classes.closeIcon}
            onClick={close}
            fontSize="large"
          />
        </Box>
        <Typography variant="h6" className={classes.title}>
          Du har aktivt frikort
        </Typography>
        <Box mt={1.5} />
        <Typography className={classes.text}>
          Du har uppnått högkostnadsskyddet och besöket kostar 0 kr.{"\n"}
        </Typography>
        <Typography className={classes.text}>
          (Frikort giltigt t.o.m. {getFormattedFreeCardDate(expirationDate)})
        </Typography>
        <Box className={classes.buttonContainer}>
          <Button onClick={close} className={classes.button}>
            <Typography style={{ textTransform: "none" }}>GÅ VIDARE</Typography>
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}

export default NotifyFrikortModal
