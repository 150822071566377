import {
  Modal,
  Box,
  Typography,
  Link as LinkButton,
  Button,
} from "@mui/material"
import { makeStyles } from "@mui/styles"
import { sessionType } from "utils/sessionType"
import { trackEvent } from "utils/trackEvent"
import { fetchCheckoutSession } from "api/fetchCheckoutSession"
import mastercard from "assets/Mastercard.svg"
import visa from "assets/Visa.svg"
import klarna from "assets/Klarna.svg"
import swish from "assets/Swish.svg"
import { LATE_CANCELLATION_FEE } from "utils/constants"

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(4),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  wrapper: {
    backgroundColor: theme.palette.white,
    padding: theme.spacing(4, 10),
    borderRadius: theme.spacing(2),
    outline: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    overflowY: "auto",
    [theme.breakpoints.up("sm")]: {
      maxWidth: "60%",
    },
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
      maxWidth: "100%",
      maxHeight: "100%",
    },
  },
  buttonContainer: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    marginTop: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      display: "flex",
      marginTop: theme.spacing(1),
    },
  },
  button: {
    display: "flex",
    flex: 1,
    padding: theme.spacing(1),
    textTransform: "none",
    borderRadius: theme.spacing(5),
    width: "70%",
    textAlign: "center",
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: "1.25rem",
    lineHeight: 1.6,
    "&:disabled": {
      color: "rgba(0, 0, 0, 0.26)",
      boxShadow: "none",
      backgroundColor: "rgba(0, 0, 0, 0.12)",
      pointerEvents: "none",
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
      "&:last-of-type": {
        marginTop: theme.spacing(1),
      },
    },
  },
  paymentMethodIcons: {
    marginTop: theme.spacing(3),
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "210px",
  },
  swish: {
    height: "32px",
  },
  visa: {
    height: "22px",
  },
  mastercard: {
    height: "22px",
  },
  klarna: {
    height: "22px",
  },
  freecardLink: {
    marginTop: theme.spacing(2),
    textDecoration: "underline",
  },
  title: {
    textAlign: "center",
    fontWeight: theme.typography.fontWeightMedium,
  },
  text: {
    color: theme.palette.black,
    "& a": {
      textDecoration: "none",
      color: theme.palette.primary.main,
    },
    "& a:hover": {
      cursor: "pointer",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(0),
      fontSize: "0.9rem",
    },
  },
  textContainer: {
    margin: theme.spacing(3, 0, 1),
    textAlign: "center",
  },
  captionTitle: {
    color: theme.palette.primary.main,
    padding: theme.spacing(1),
  },
}))

const VideoPaymentModal = ({
  open,
  close,
  onPaymentDecision,
  selectedVideoCallSlot,
  caregiverId,
  userId,
  getUserProfile,
  showAlert,
  videoMeetingPaymentLink,
  freeCardAmountToLimit,
}) => {
  const classes = useStyles()

  const redirectToCheckout = async () => {
    try {
      const { start, end, timeZone: slotTimeZone } = selectedVideoCallSlot
      const {
        data: { url },
      } = await fetchCheckoutSession({
        type: sessionType.VIDEO,
        details: {
          start,
          end,
          caregiverId,
          slotTimeZone,
          userTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
        freeCardAmountToLimit,
      })
      window.location.href = url
    } catch (error) {
      if (error?.response?.status === 405) {
        window.location.reload()
      } else {
        let errorMessage =
          "Tiden är dessvärre inte längre tillgänglig. Var god och välj en annan tid."

        if (error?.response?.status === 401) {
          errorMessage = "Tiden kan ej bokas så nära inpå mötet."
        } else if ([400, 403, 503].includes(error?.response?.status)) {
          errorMessage = error?.response?.data
            ? error.response.data
            : errorMessage
        }
        if (error?.response?.status === 400) {
          await getUserProfile(userId)
          close()
        }
        showAlert({
          type: "error",
          message: errorMessage,
        })
      }
    }
  }

  return (
    <Modal className={classes.root} open={open} onClose={close}>
      <Box className={classes.wrapper}>
        <Typography variant="body1" className={classes.title}>
          Patientavgift 100 kr
        </Typography>
        <Box className={classes.buttonContainer}>
          <Button
            className={classes.button}
            size="large"
            variant="contained"
            onClick={(e) => {
              e.target.disabled = true
              trackEvent("paymentChoice", { userId, paymentType: "card" })
              return videoMeetingPaymentLink
                ? (window.location.href = videoMeetingPaymentLink)
                : redirectToCheckout()
            }}
          >
            Till betalning
          </Button>
          <Box className={classes.paymentMethodIcons}>
            <img className={classes.swish} src={swish} alt="Swish Icon" />
            <img className={classes.visa} src={visa} alt="Visa Icon" />
            <img
              className={classes.mastercard}
              src={mastercard}
              alt="Mastercard Icon"
            />
            <img className={classes.klarna} src={klarna} alt="Klarna Icon" />
          </Box>
          <LinkButton
            className={classes.freecardLink}
            component="button"
            variant="body2"
            onClick={() => {
              trackEvent("paymentChoice", { userId, paymentType: "freecard" })
              onPaymentDecision()
            }}
          >
            <Typography variant="subtitle2">Jag har frikort</Typography>
          </LinkButton>
        </Box>
        <Box className={classes.textContainer}>
          <Typography className={classes.captionSubtitle}>
            Avbokning är kostnadsfritt upp till 24 timmar innan samtal.
          </Typography>
          <Typography className={classes.captionSubtitle}>
            Om du avbokar senare eller uteblir debiteras en avgift på{" "}
            {LATE_CANCELLATION_FEE} kr.
          </Typography>
        </Box>
      </Box>
    </Modal>
  )
}

export default VideoPaymentModal
